<!--
 * @Author: lbh
 * @Date: 2022-06-15 12:03:27
 * @LastEditors: lbh
 * @LastEditTime: 2024-04-11 16:15:10
 * @Description: 圖片
-->
<template>
  <div>

    <el-image
      class="image-box"
      :src="showPhone ? configs.phoneSrc : configs.src"
      :fit="configs.fit"
      :class="`edit_${Xindex}_src`"
      @click="clickItem('src')"
      :alt="configs.alt"
    ></el-image>
    <!-- <progressiveImage
      :placeholder="`${configs.src}?x-oss-process=image/resize,h_100`"
      :origin="configs.src"
    /> -->
  </div>
</template>

<script>

export default {
  name: "self-image",
  props: {
    configs: {
      default () {
        return {
          src: "",
          alt: '',
          fit: "contain" // fill cover scale-down
        }
      }
    },
    Xindex: {
      default () {
        return '';
      }
    }
  },
  data () {
    return {
      showPhone: false
    }
  },
  created () {
    console.log(333);
    this.$listen.listen('image', e => {
      if (e < 1000) this.showPhone = true;
      else this.showPhone = false;
    })
  },
  destroyed () {
    this.$listen.removeListen('image')
  },
  methods: {
    clickItem (e) {
      console.log(e);
      let isEdit = this.$store.getters.getIsEdit;
      if (isEdit) {
        this.$emit('clickItem', e);
      } else {

      }
    }
  }
}
</script>

<style lang="less" scoped>
.image-box {
  width: 100%;
  vertical-align: middle;
}
</style>